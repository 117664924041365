// Dependencies
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

// Components
import Template from '../../components/Template/Template'
import Videos from './Videos'
import PlayerControls from '../../components/Musicas/PlayerControls'
import Helmet from '../../components/Template/Helmet'

// Styles
import '../../assets/index.css'
import Navigation from '../../components/Template/Navigation'

// Context
import useMicrocopyContext from '../../context/useMicrocopyContext'

// Helpers
import { createMetaObject, getPageData } from '../../helpers/utils'

const VideosPage = (props) => {
  const { state, setMicrocopy } = useMicrocopyContext()
  const [pageData, setPageData] = useState({})

  const { pageContext } = props

  useEffect(() => {
    const pageState = getPageData(props)
    setPageData(pageState)
    setMicrocopy(pageState.microcopy)
  }, [props])

  const {
    general,
    home,
    about,
    music,
    images,
    videos,
    contact,
    events,
    dates,
    social,
  } = pageData

  const metaTags = createMetaObject(
    pageContext.general,
    pageContext.metaTags['videos'].metaTags,
    pageContext.metaTags['videos'].introTitle
  )

  return (
    <>
      <Helmet {...metaTags} />
      <Navigation {...pageContext.general} />
      {videos && (
        <>
          <Template location={props.location}>
            <Videos
              {...{
                general,
                home,
                about,
                music,
                images,
                videos,
                contact,
                events,
                dates,
                social,
              }}
            />
          </Template>
          <PlayerControls albums={music.albums} />
        </>
      )}
    </>
  )
}

export default VideosPage

export const pageQuery = graphql`
  query(
    $home: String
    $about: String
    $events: String
    $music: String
    $images: String
    $videos: String
    $contact: String
  ) {
    home: contentfulHomeContent(id: { eq: $home }) {
      ...homeQuery
    }
    about: contentfulAboutContent(id: { eq: $about }) {
      ...aboutQuery
    }
    events: contentfulEventsContent(id: { eq: $events }) {
      ...eventsQuery
    }
    music: contentfulMusicContent(id: { eq: $music }) {
      ...musicQuery
    }
    images: contentfulImageGalleriesContent(id: { eq: $images }) {
      ...imageGalleriesQuery
    }
    videos: contentfulVideoGalleriesContent(id: { eq: $videos }) {
      ...videoGalleriesQuery
    }
    contact: contentfulContactContent(id: { eq: $contact }) {
      ...contactQuery
    }
    allContentfulMicrocopySet {
      edges {
        node {
          key
          values {
            key
            value
          }
        }
      }
    }
    allContentfulGeneralContent {
      edges {
        node {
          ...generalQuery
        }
      }
    }
    allContentfulEventContent {
      edges {
        node {
          ...eventQuery
        }
      }
    }
    allContentfulSocialContent {
      edges {
        node {
          ...socialQuery
        }
      }
    }
  }
`
